<template>
  <div class="tabContainer intersection">
      <div class="container-fluid">
        <div class="row">
          <div class="contentBlock col-md-6">
            <div class="card m-b-0">
              <div v-if="isLoading" class="skeletonContainer">
                <b-skeleton width="80%" :animated="true"></b-skeleton>
                <b-skeleton width="40%" :animated="true"></b-skeleton>
                <b-skeleton width="20%" :animated="true"></b-skeleton>
                <b-skeleton :animated="true"></b-skeleton>
              </div>
              <div v-if="!isLoading" class="card-body">
                <div class="targetListHeader">
                  <div class="titleContainer">
                    <div class="border-checkbox-section">
                      <div class="border-checkbox-group border-checkbox-group-primary">
                        <input type="checkbox" class="border-checkbox" v-model="hideInactiveTask" id="showOnlyActiveIntersection"/>
                        <!-- <label v-if="hideInactiveTask" class="border-checkbox-label" for="showOnlyActiveIntersection">{{$t('monitoringLocation.showInactiveTask')}}</label> -->
                        <label class="border-checkbox-label" for="showOnlyActiveIntersection">{{$t('monitoringLocation.hideInactiveTask')}}</label>
                      </div>
                    </div>
                  </div>
                  <intersection-task-create-modal
                    @updateList="getAreaTrackingTask"
                  />
                </div>

                <div class="targetList" >
                  <div class="m-b-10">
                    <div class="filter-users" style="height: 100%">
                      <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          class="search-filter-monitoring"
                      ></b-form-input>
                    </div>
                  </div>
                  <b-table
                      show-empty
                      sticky-header
                      :items="filteredTasks"
                      :fields="fields"
                      :filter-function="filterTable"
                      :filter="filter"
                      :busy="isLoading"
                      :tbody-tr-class="rowClass"
                      small
                  >
                  <template #empty="scope">{{ $t('monitoringLocation.noTargets') }}
                  </template>
                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>


                    <template #cell(1)="row">
                      <div class="border-checkbox-section">
                        <div class="border-checkbox-group border-checkbox-group-primary">
                          <input class="border-checkbox"
                                 type="checkbox"
                                 :id="`taskCreate`+row.item.id"
                                 :disabled="!selectedPairs.includes(row.item.id) && selectedPairs.length > 0 "
                                 :value="row.item.id"
                                 v-model="selectedPairs">
                          <label class="border-checkbox-label" :for="`taskCreate`+row.item.id" style="height: auto;">
                            <div v-for="(target, index) in row.item.targets" style="display: flex; justify-content: space-between">
                              <span v-if="target.alias && target.alias.length <= 25">{{target.alias}} </span>
                              <span v-else :class="{'hideText': target.alias.length > 25}" :content="target.alias" v-tippy="{ placement : 'top',  arrow: true }">{{target.alias}} </span>
                              <div class="circle  m-l-10" :class="[`color-tag-${index}`]"></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </template>

<!--                    <template #cell(1)="row">-->
<!--                      <div class="border-checkbox-section">-->
<!--                        <div class="border-checkbox-group border-checkbox-group-primary" style="display:flex;">-->
<!--                          <input class="border-checkbox"-->
<!--                                 type="checkbox"-->
<!--                                 :id="`taskCreate`+row.item.id"-->
<!--                                 :disabled="!selectedPairs.includes(row.item.id) && selectedPairs.length > 0 "-->
<!--                                 :value="row.item.id"-->
<!--                                 v-model="selectedPairs">-->
<!--                          <label class="border-checkbox-label" :for="`taskCreate`+row.item.id" style="display: inline-flex">-->
<!--                          </label>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </template>-->

<!--                    <template #cell(targets[0])="row">-->
<!--                      <div  class=" d-flex" style="justify-content: start;align-items: center">-->
<!--                        <span v-if="row.value.alias && row.value.alias.length <= 25">{{row.value.alias}} </span>-->
<!--                        <span v-else :class="{'hideText': row.value.alias > 25}" :content="row.value.alias" v-tippy="{ placement : 'top',  arrow: true }">{{row.value.alias}} </span>-->
<!--                        <div class="circle color-tag-0 m-l-10"></div>-->
<!--                      </div>-->
<!--                    </template>-->

<!--                    <template #cell(targets[1])="row">-->
<!--                      <div  class=" d-flex" style="justify-content: start;align-items: center">-->
<!--                        <span v-if="row.value.alias && row.value.alias.length <= 25">{{row.value.alias}} </span>-->
<!--                        <span v-else :class="{'hideText': row.value.alias.length > 25}" :content="row.value.alias" v-tippy="{ placement : 'top',  arrow: true }">{{row.value.alias}} </span>-->
<!--                        <div class="circle color-tag-1 m-l-10"></div>-->
<!--                      </div>-->
<!--                    </template>-->

                    <template #cell(note)="row">
                        <i class="feather icon-file-text f-16 m-l-10" v-if="row.value" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+row.value" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                    </template>

                    <template #cell(distance)="row">
                        <div>{{row.value}} {{$t('geo.meters')}}</div>
                    </template>

                    <template #cell(2)="row">
                      <intersection-task-update-modal
                          :rowItem="row.item"
                          @getAreaTrackingTask="getAreaTrackingTask"
                      />
                    </template>

                  </b-table>
                </div>

                <div class="card paginationCard" v-if="typeof crossTrackingLogs.count !== 'undefined' && crossTrackingLogs.total > crossTrackingLogs.count">
                  <div class="block paginationBlock">
                  <span class="p-size">
                    <span v-html="$tc('geo.total_find', crossTrackingLogs.total, {n: crossTrackingLogs.total} )"></span>
                    <span v-if="crossTrackingLogs.count > 1">
                    <span>, </span>
                    <span  v-html="$tc('result.total_show', crossTrackingLogs.count , {n : crossTrackingLogs.count})"></span>
                    </span>
                    <span v-else-if="crossTrackingLogs.count === 1">
                    <span>, </span>
                    <span  v-html="$tc('result.one_show', crossTrackingLogs.count , {n : crossTrackingLogs.count})"></span>
                    </span>
                  </span>
                    <button class="btn btn-primary" @click="paginate">
                      <b-spinner small v-if="isPaginate"></b-spinner>
                      <span style="text-transform: none">{{ $t('geo.show_more') }}</span>
                    </button>
                  </div>
                </div>
                <div v-else class="paceHolder" style="height: 58px"></div>

                <div class="card">
                  <div class="block rangeSelectBlock">
                    <div class="rangeSelectBlockTitle"><p class="m-b-0">{{ $t('monitoringLocation.period_intersection') }}</p></div>
                    <date-range-picker
                        ref="picker"
                        :ranges="calendarTranslate.ranges[$i18n.locale]"
                        v-model="dateRange"
                        :locale-data="calendarTranslate.calendar[$i18n.locale]"
                    >
                      <template v-slot:input="picker">
                        <i class="feather icon-calendar m-r-5"></i>
                        {{ moment(picker.startDate).format('DD.MM.YYYY') }} - {{ moment(picker.endDate).format('DD.MM.YYYY') }}
                      </template>
                      <div slot="footer" slot-scope="data" class="slot d-lg-flex justify-content-xl-end">
                        <div class="d-flex align-items-center">
                          <b >{{ data.rangeText }}</b>
                        </div>
                        <div class="d-grid p-10 d-lg-flex ">
                          <b-button @click="data.clickApply" variant="primary" v-if="!data.in_selection" class="acceptButton btn">{{ $t('billing.applyLabel') }}</b-button>
                          <button @click="data.clickCancel" class="btn btn-secondary m-l-10">{{ $t('billing.cancelLabel') }}</button>
                        </div>
                      </div>
                    </date-range-picker>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="contentBlock col-md-6">
            <div class="mapBlock">
              <div id="mapIntersections" style="height: 554px;"></div>
            </div>
          </div>

          <div class="logsTable card colored" style="padding: 16px; margin-top: 16px" v-if="selectedPairs.length || showEmpty">
            <b-table
                :items="crossTrackingLogs.data"
                :fields="logsFields"
                :busy="logLoading"
                :tbody-tr-class="crossTrackingLogsRowClass"
                small
                show-empty
                hover
            >

              <template #empty="scope">{{ $t('monitoringLocation.no_events') }}
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="row">
                {{ row.value }}
              </template>

              <template #cell(created_at)="row">
                {{ moment(row.value, 'X').format('DD MMMM YYYY HH:mm')}}
              </template>

              <template #cell(locations[0])="row">
                <div v-if="selectedPairs.length" style="display: flex; align-items: center"  @click="selectPoint(row.item, true)">
                  <span v-if="getSelectedTask(0).length <= 20">{{getSelectedTask(0)}}</span>
                  <span v-else :class="{'hideText': getSelectedTask(0).length > 20}" :content="getSelectedTask(0)" v-tippy="{ placement : 'top',  arrow: true  }">{{getSelectedTask(0)}}</span>
                  <i class="feather icon-crosshair search m-l-10  color-target-tag-0" ></i>
                </div>
                <div v-else style="display: flex; align-items: center">
                  <span v-if="getTargetAlias(row.value.target_id).length <= 20">{{getTargetAlias(row.value.target_id)}}</span>
                  <span v-else :class="{'hideText': getTargetAlias(row.value.target_id).length > 20}" :content="getTargetAlias(row.value.target_id)" v-tippy="{ placement : 'top',  arrow: true  }">{{getTargetAlias(row.value.target_id)}}</span>
                </div>
              </template>

              <template #cell(locations[1])="row">
                <div v-if="selectedPairs.length"  style="display: flex; align-items: center" @click="selectPoint(row.item, true)">
                  <span v-if="getSelectedTask(1).length <= 20">{{getSelectedTask(1)}}</span>
                  <span v-else :class="{'hideText': getSelectedTask(1).length > 20}" :content="getSelectedTask(1)" v-tippy="{ placement : 'top',  arrow: true  }">{{getSelectedTask(1)}}</span>
                  <i class="feather icon-crosshair search m-l-10 color-target-tag-1" ></i>
                </div>
                <div v-else style="display: flex; align-items: center">
                  <span v-if="getTargetAlias(row.value.target_id).length <= 20">{{getTargetAlias(row.value.target_id)}}</span>
                  <span v-else :class="{'hideText': getTargetAlias(row.value.target_id).length > 20}" :content="getTargetAlias(row.value.target_id)" v-tippy="{ placement : 'top',  arrow: true  }">{{getTargetAlias(row.value.target_id)}}</span>
                </div>
              </template>

            </b-table>
          </div>

        </div>
      </div>

  </div>
</template>

<script>
import drawLocales from "leaflet-draw-locales";
import L from "leaflet";
import moment from "moment";
import {mapGetters} from "vuex";
import IntersectionsTaskCreate from "@/components/monitoringLocation/intersections-task-create";
import IntersectionTaskCreateModal from "@/components/monitoringLocation/intersectionTaskCreateModal";
import _ from "underscore";
import wkt from "wkt";
import i18n from "@/i18n";
import calendarTranslate from "@/configs/calendarTranslate";
import DateRangePicker from 'vue2-daterange-picker'
import IntersectionTaskUpdateModal from "@/components/monitoringLocation/intersectionTaskUpdateModal";

export default {
  setup() {
    const capitalize = (value) =>
    {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return {
      moment,
      calendarTranslate,
      capitalize,
      isUndefined: _.isUndefined
    }
  },
  name: "intersections",
  components: {IntersectionTaskUpdateModal, IntersectionTaskCreateModal, IntersectionsTaskCreate, DateRangePicker},
  data() {
    return {
      url: "//{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: [55.019531, 41.71803],
      bounds: null,
      map: null,
      drawnItems: null,
      selectedPins: null,
      locale: this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale,
      startZoom: 3,
      isLoading: false,
      logLoading: false,
      selectedPairs : [],
      selectedTargetsIds: [],
      selectedLogIndex: null,
      colors: ['rgba(0,123,255,0.6)','rgba(40,167,69,0.6)','rgba(23,162,184,0.6)', 'rgba(220,53,69,0.6)', 'rgba(55,71,79,0.6)'],
      dateRange: {
        startDate: moment().subtract(6, 'days').toDate(),
        endDate:   moment().toDate(),
      },
      isPaginate: false,
      dateChange: false,
      showEmpty: false,
      hideInactiveTask: false,
      filter: null
    }
  },
  beforeDestroy() {
    this.$store.dispatch('locationMonitoring/clearCrossTrackingTasksLogs')
  },
  computed: {
    ...mapGetters('locationMonitoring', ['crossTrackingTasks', 'crossTrackingLogs']),
    ...mapGetters('monitoring', ['targets']),
    filteredTasks(){
      let arr = this.crossTrackingTasks;
      if (this.hideInactiveTask){
        arr = arr.filter(item=> item.status)
      }
      return arr
    },
    fields(){
      return  [
        {
          key: '1',
          label:  this.$t('monitoringLocation.targets'),
          sortable: false
        },
        // {
        //   key: 'targets[0]',
        //   label:  this.$t('monitoringLocation.target_1'),
        //   sortable: false
        // },
        // {
        //   key: 'targets[1]',
        //   label:  this.$t('monitoringLocation.target_2'),
        //   sortable: false
        // },
        {
          key: 'note',
          label: this.$t('monitoringLocation.noteArea'),
          sortable: false
        },
        {
          key: 'distance',
          label:  this.$t('monitoringLocation.tooltip_distance'),
          sortable: false
        },
        {
          key: '2',
          label:  this.$t('monitoring.table_action'),
          sortable: false,
          class: 'actionsBlock'
        },
      ]
    },
    logsFields(){
      return  [
        // {
        //   key: 'id',
        //   label:  'Id',
        //   sortable: false
        // },
        {
          key: 'created_at',
          label:  this.$t('monitoringLocation.crossing_time'),
          sortable: true
        },
        // {
        //   key: 'task_distance',
        //   label:  'Задана відстань',
        //   sortable: false
        // },
        {
          key: 'actual_distance',
          label:  this.$t('monitoringLocation.distance'),
          sortable: false
        },

        {
          key: 'locations[0]',
          label:  this.$t('monitoringLocation.target_1'),
          sortable: false
        },
        {
          key: 'locations[1]',
          label:  this.$t('monitoringLocation.target_2'),
          sortable: false
        },
      ]
    },
  },
  watch: {
    selectedPairs(){
     this.getLogs()
    },
    'dateRange.endDate': function (){
      if(!this.dateChange){
        this.dateChange = true;
        this.getLogs()
      }
    },
    'dateRange.startDate': function (){
      if(!this.dateChange){
        this.dateChange = true;
        this.getLogs()
      }
    },
  },
  methods:{
    filterTable(row, filter){
      console.log(row)
      let targetsAliases = '';
      row.targets.forEach(target => {
        targetsAliases = targetsAliases + target.alias
      })
      if (targetsAliases.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
          row.note && row.note.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        return true;
      } else {
        return false;
      }
    },

    getSelectedTask(index){
      if (this.crossTrackingTasks.length > 0 && this.selectedPairs.length > 0){
        let task = this.crossTrackingTasks.find(item => item.id === this.selectedPairs[0])
        return task.targets[index].alias
      }
    },

    paginate(){
      if (this.crossTrackingTasks.length){
        let payload = {
          filters: {
            task_id: this.selectedPairs,
            created_from: moment(this.dateRange.startDate).startOf('day').format('X'),
            created_to: moment(this.dateRange.endDate).endOf('day').format('X'),
          },
          limit: 10,
          offset: this.crossTrackingLogs.count,
        }
        this.logLoading = true
        this.$store.dispatch('locationMonitoring/crossTrackingTasksLogsPagination', payload).then(()=>{
          this.crossTrackingPoints();
          this.dateChange = false;
          this.logLoading = false;
        })
      }
    },

    getLogs(){
      if (this.selectedPairs.length  > 0){
        let payload = {
          order_type: "desc",
          filters: {
            task_id: this.selectedPairs,
            created_from: moment(this.dateRange.startDate).startOf('day').format('X'),
            created_to: moment(this.dateRange.endDate).endOf('day').format('X'),
          },
          limit: 10,
        };
        this.logLoading = true;
        this.$store.dispatch('locationMonitoring/getCrossTrackingTasksLogs', payload).then(()=>{
          this.crossTrackingPoints();
          this.dateChange = false;
          this.logLoading = false;
          localStorage.setItem('lastWatchedIntersectionsLogsTime', moment().format('X'))
        })
      } else {      
        if(this.drawnItems) {
          this.drawnItems.clearLayers();
        }
      }
    },

    getNonSelectedLogs() {
      const payload = {
        order_type: "desc",
        filters: {
          created_from: moment(this.dateRange.startDate).startOf('day').format('X'),
          created_to: moment(this.dateRange.endDate).endOf('day').format('X'),
        },
      };
      this.logLoading = true;
      this.$store.dispatch('locationMonitoring/getCrossTrackingTasksLogs', payload).then(()=>{
        this.dateChange = false;
        this.logLoading = false;
        localStorage.setItem('lastWatchedIntersectionsLogsTime', moment().format('X'))
      })
    },

    crossTrackingPoints(){
      if(this.drawnItems) {
        this.drawnItems.clearLayers();
      }
      let selectedTargetsIds = [];
      let temp =  this.crossTrackingTasks.find(el=> el.id === this.selectedPairs[0])
      temp.targets.forEach(target => {
        selectedTargetsIds.push(target.target_id)
      })
      this.selectedTargetsIds = selectedTargetsIds;
      if (this.crossTrackingLogs.data.length){
        this.crossTrackingLogs.data.forEach(log => {
          log.locations.forEach(location => {
            let index = this.selectedTargetsIds.indexOf(location.target_id)

            const svgTemplate = `<div class="intersectionPin markerIndex-${log.id} color-${index}"><div class="marker-icon">${log.id}</div></div>`;
            const icon = L.divIcon({
              className: "custom-div-icon",
              html: svgTemplate,
              popupAnchor: [0, -2]
            });

            let pointCoordinates = wkt.parse(location.location)
            pointCoordinates.coordinates.reverse();
            let marker = L.marker(pointCoordinates.coordinates, {
              icon: icon,
              log: log,
              logIndex: log.id
            });
            marker.on('click', this.selectPoint)
            marker.bindTooltip(this.pinTooltip(location), {
              direction: 'top',
              offset: L.point(-6, -22)
            });
            marker.addTo(this.drawnItems);
            this.map.fitBounds(this.drawnItems.getBounds());
          })
        })
      }
    },
    selectPoint(crossTrackingLog, fromTable){
      if(this.selectedPins) {
        this.selectedPins.clearLayers();
      }
      this.selectedLogIndex = null;
      let coord = [];
      let log;
      if (fromTable){
        log  = crossTrackingLog
      } else {
        log = crossTrackingLog.target.options.log;
      }
      this.selectedLogIndex = log.id
      log.locations.forEach(location => {
        let index = this.selectedTargetsIds.indexOf(location.target_id)
        let coords = wkt.parse(location.location)
        coord.push(coords.coordinates.reverse())
        const svgTemplate = `<div class="selectedPins markerIndex-${log.id} color-${index}"><div class="marker-icon">${log.id}</div></div>`;
        const icon = L.divIcon({
          className: "custom-selected-pin",
          style: 'z-index: 500',
          html: svgTemplate,
        });
        let pointCoordinates = wkt.parse(location.location)
        pointCoordinates.coordinates.reverse();
        let marker = L.marker(pointCoordinates.coordinates, {
          icon: icon,
          log: log,
          logIndex: log.id
        });
        marker.on('click', this.selectPoint)
        marker.bindTooltip(this.pinTooltip(location), {
          direction: 'top',
          offset: L.point(-6, -30)
        });
        marker.addTo(this.selectedPins);
      })
      const wellBounds = new L.latLngBounds(coord);
      this.map.fitBounds(wellBounds);
    },
    pinTooltip(point){
      let loc
      if (this.$i18n.locale === 'ua'){
        loc =  'uk'
      } else {
        loc =  this.$i18n.locale
      }
      let text = ''
      moment.locale(loc);
      let sameDay = moment(point.period_from).isSame(point.period_to, 'day');
      let sameTime = point.period_from === point.period_to
      if (sameDay){
        if (sameTime){
          text += moment(point.period_from, 'X').format('DD MMMM YYYY') +', '+ moment(point.period_from, 'X').format('HH:mm')
        } else {
          text += moment(point.period_from, 'X').format('DD MMMM YYYY') +', '+ moment(point.period_from, 'X').format('HH:mm') +' - ' + moment(point.period_to, 'X').format('HH:mm')
        }
      } else {
        text += moment(point.period_from, 'X').format('DD MMMM YYYY HH:mm') + ' - ' + moment(point.period_to, 'X').format('DD MMMM YYYY HH:mm')
      }
      return text
    },
    async initIntersectionsMap() {
      const locale = drawLocales(this.locale)
      L.drawLocal = locale;
      L.Draw.Polygon.prototype._onTouch = L.Util.falseFn;

      this.map = await L.map('mapIntersections', {
        zoomControl: false,
        attributionControl: false,
        center: this.center,
        zoom: this.startZoom
      });

      L.tileLayer(this.url, { maxZoom: 19, minZoom: 3, openLegendOnLoad: false, attribution: '', noWrap: true }).addTo(this.map)
      L.control.zoom({
        zoomInTitle: this.$t('geo.zoomIn'),
        zoomOutTitle: this.$t('geo.zoomOut')
      }).addTo(this.map)

      var southWest = L.latLng(-89.98155760646617, -180),
          northEast = L.latLng(89.99346179538875, 180);
      var bounds = L.latLngBounds(southWest, northEast);

      this.map.setMaxBounds(bounds);
      this.map.on('drag', () => {
        this.map.panInsideBounds(bounds, { animate: false });
      });
      this.drawnItems = new L.FeatureGroup().addTo(this.map);
      this.selectedPins = new L.FeatureGroup().addTo(this.map);
    },
    getAreaTrackingTask(){
      this.isLoading = true;
      this.$store.dispatch('locationMonitoring/getCrossTrackingTasks').then(()=> {
        this.isLoading = false;
      })
    },
    rowClass(item){
        let index = this.selectedPairs.findIndex((element) => item.id === element)
        if (index !== -1){
          // return 'color-'+ index
          return 'colored'
        }
    },
    crossTrackingLogsRowClass(item){
      if (item){
        if (item.id === this.selectedLogIndex){
          return 'highlightRow'
        }
      }
    },
    preventFilter() {
      const notification = this.$store.getters['locationMonitoring/notificationMonitoring']
      
      if(notification && notification.type === 'crossTracking') {
        this.selectedPairs = notification.taskId ? [notification.taskId] : []
        const time = localStorage.getItem('lastWatchedIntersectionsLogsTime')
        const startDate = time ? moment(time, 'X').toDate() : moment().subtract(6, 'days').toDate()
        this.dateRange = {
          startDate,
          endDate: moment().toDate(),
        }
        if(notification.taskId) {
          this.getLogs()
        } else {
          this.showEmpty = true
          this.getNonSelectedLogs()
        }
        this.$store.commit('locationMonitoring/setNotificationCrossTrackingTasksCount', 0);        
      }

    },
    updateMap(){
      setTimeout(() => {
        this.map.invalidateSize();
      }, 100)
    },
    getTargetAlias(targetId) {
      const target = this.targets.find(target => target.target_id === targetId);
      return target.alias;
    },
  },
  mounted() {
    this.getAreaTrackingTask();
    this.initIntersectionsMap();
    this.preventFilter()
  }

}
</script>

<style lang="scss">
.intersection{
  .targetList{
    .border-checkbox-section{
      .border-checkbox-label::before{
        margin-top: 10px;
      }
      .border-checkbox-label::after{
        margin-top: 10px;
      }
    }
  }

  .b-table-sticky-header{
    height: 296px;
    margin-bottom: 0;
  }
  .hideText {
    width: 5vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .targetListHeader{
    display: flex;
    justify-content: space-between;
  }
}
.tippy-popper{
  word-break: break-word;
}
.tabContainer {
  .rangeSelectBlock{
    .daterangepicker {
      top: -315px !important;
      &::before{
        top: 301px !important;
        transform: rotate(180deg) !important;
      }
      &::after{
        top: 301px !important;
        transform: rotate(180deg) !important;
      }
    }
  }
  .highlightRow {
    background-color: rgb(190, 190, 190);
  }

  .circle{
    margin-top: 4px;
    height: 10px !important;
    width: 10px !important;
    max-height: 10px !important;
    max-width: 10px !important;
    min-height: 10px !important;
    min-width: 10px !important;
    border-radius: 50%;
    margin-right: 4px;
  }
  .rangeSelectBlock {
    display: flex;
    justify-content: space-between;
    padding: 8px 14px 8px 14px;

    .rangeSelectBlockTitle {
      display: flex;
      align-items: center;
    }
  }
  .leaflet-tooltip-pane>* {
    width: 300px !important;
    text-align: center !important;
    white-space: normal !important;
  }
  .leaflet-tooltip {
    background-color: white;
    border: 1px solid #ccc;
    padding: 6px 10px;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  }

  .card {
    margin-bottom: 8px;
  }
  .paginationCard {
    padding: 8px 14px;
    .paginationBlock{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  h4 {
    margin-bottom: 0;
  }
  .targetsPairs:last-child{
    margin-left: 10px;
  }

  .intersectionPin{
    width: 22px;
    height: 22px;
    position: absolute;
    left: -11px;
    top: -11px;
    border-radius: 50%;
    .marker-icon {
      position: absolute;
      bottom: 4px;
      left: 4px;
      width: 14px;
      height: 14px;
      text-align: center;
      color: black;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }


  .custom-selected-pin{
    z-index:1000 !important;
    .marker-icon {
      z-index: 1001 !important;
    }
  }
  .selectedPins{
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    top: -22px;
    left: -22px;
    .marker-icon {
      position: absolute;
      bottom: 11px;
      left: 11px;
      width: 22px;
      height: 22px;
      text-align: center;
      color: black;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
  }



  .colored {
    .color-tag-0 {
      background-color: rgba(0, 123, 255, 0.6);
    }

    .color-tag-1 {
      background-color: rgba(40, 167, 69, 0.6);
    }

    .color-tag-2 {
      background-color: rgba(23, 162, 184, 0.6);
    }

    .color-tag-3 {
      background-color: rgba(220, 53, 69, 0.6);
    }

    .color-tag-4 {
      background-color: rgba(55, 71, 79, 0.6);
    }

    .color-target-tag-0 {
      color: rgba(0, 123, 255, 0.6);
    }

    .color-target-tag-1 {
      color: rgba(40, 167, 69, 0.6);
    }


  }

}
</style>