<template>
  <div style="max-width: 1200px; margin: 0 auto;">
    <div style="display: flex; align-items: center; justify-content: center;">
      <h1>{{ $t('search.search_results') }}</h1>
    </div>

    <h2 v-if="parentSearch.source_locales" style="margin-top: 40px">
      {{ parentSearch.source_locales[selectedLocale()] }}
    </h2>

    <div v-for="item in parentSearch.search_results" style="display: flex; margin-top: 30px">
      <div v-for="photo in item.photos" :key="photo.date" v-if="item.photos.length" style="margin-right: 20px">
        <image-profile
            size="110px"
            :payload="photo.image"
        />
        <div>{{ moment.unix(photo.date).format('DD.MM.YYYY HH:mm') }}</div>
      </div>
      <div style="font-size: 20px;">
        <div v-for="data in item.data" :key="item.id" style="display: flex; padding-bottom: 10px">
          <div style="width: 120px;">{{ data.param }}:</div>
          <div>
            {{ data.value }}
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 120px;">communities:</div>
          <div>{{ item.relation_counters.communities }}</div>
        </div>
      </div>
    </div>

    <div style="margin-top: 30px">
      <h3>Загалом користувач залишив: {{ totalCountComments }} повідомлень.</h3>
    </div>

    <div v-for="treeComment in treeComments">

      <div style="margin-left: 30px;">
        <div>- у спільноті:</div>
        <div v-for="data in treeComment.data">
          <span v-if="data.param === 'name'">{{ data.value }}</span>
        </div>
      </div>

      <div v-for="comment in treeComment.comments">
        <CommentTree :comment="comment"/>
      </div>
    </div>
  </div>
</template>

<script>
import CommentTree from "@/components/export/CommentTree.vue";
import {Result} from "@/models/result";
import ImageProfile from "@/components/ImageProfile.vue";
import moment from "moment";

export default {
  props: {
    full: {
      type: Boolean,
      required: true,
    },
    meta: {
      type: Object,
      required: true
    },

    token: {
      type: String,
      required: true
    }
  },

  components: {ImageProfile, CommentTree},

  data() {
    return {
      limit: 500,
      treeComments: [],
      style: '',
      totalCountComments: 0,
      parentSearch: {},
    }
  },

  mounted() {
    this.selectedLocale();
    this.getTelegramGroupCommentsPaginate().then(() => {
      this.$emit('ready');
    });
  },

  computed: {
    moment() {
      return moment
    }
  },

  methods: {
    async getTelegramGroupCommentsPaginate() {
      let comments = [];

      if (this.full) comments = await Result.getDataByPartialKey(`${this.meta.parent}_type_telegram_monitor_full`);
      if (!this.full) comments.push(await Result.getResultByKey(this.meta.key));

      let parentSearch = await Result.getResultByKey(this.meta.parent);
      this.parentSearch = parentSearch.result.results.find(item => item.source === "Telegram_scr_2023");

      for (let comment of comments) {
        let sourceComment = comment.result.results.find(item => item.source === "Telegram_scr_2023");

        let countPaginated = Math.ceil(sourceComment.matches_count / this.limit);

        this.totalCountComments += sourceComment.total_count;

        let data = {
          body: {limit: this.limit, offset: 0},
          query: {request_id: sourceComment.request_id, source_id: sourceComment.source_id}
        };

        let search_results = [];
        let authors = [];
        let group = {};

        for (let i = 0; i < countPaginated; i++) {
          let response = await this.$store.dispatch('search_comment/getTelegramGroupCommentsPaginate', data);
          data.body.offset = response.offset;

          search_results = search_results.concat(response.search_results);
          authors = authors.concat(response.relative_results.authors);
          group = response.relative_results.groups.shift();
        }

        this.buildTreeComments(search_results, authors, group);
      }

      return new Promise(resolve => setTimeout(resolve, 200));
    },

    buildTreeComments(search_results, authors, group) {
      const map = {};

      search_results.forEach(item => {
        item.data = authors.find(author => author.id === item.author_id);
        item.children = [];
        map[item.id] = item;
      });

      const result = [];

      const calculateLevel = (item, currentLevel = 0) => {
        item.level = currentLevel;

        item.children.forEach(child => {
          calculateLevel(child, currentLevel + 1);
        });
      };

      search_results.forEach(item => {
        if (item.parent_id && map[item.parent_id]) {
          map[item.parent_id].children.push(item);
        } else if (item.reply_to_id && map[item.reply_to_id]) {
          map[item.reply_to_id].children.push(item);
        } else {
          result.push(item);
        }
      });

      result.forEach(rootComment => {
        calculateLevel(rootComment);
      });

      group.comments = result;

      this.treeComments.push(group);
    },

    selectedLocale() {
      if (this.$i18n.locale === 'ua') {
        return 'uk';
      } else {
        return this.$i18n.locale
      }
    },
  }
};
</script>

<style scoped>
h1 {
  color: blue;
}

div {
  font-size: 14px;
  margin-bottom: 20px;
}
</style>