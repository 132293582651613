<template>
  <div class="d-flex flex-row-reverse">
    <button
        :content="$t('comment_search.hint.detail_preview')"
        v-tippy="{ placement : 'top',  arrow: true }"
        class="btn btn-primary m-r-10 m-b-10 m-t-5"
        @click="$bvModal.show(`telegram-group-${recordId}`)"
    >
      {{ $t('ui.detail') }}
    </button>

    <b-modal
        content-class="h-100"
        size="lg" scrollable
        :id="`telegram-group-${recordId}`"
        centered
        hide-footer
        @hidden="closeModal"
        @show="openModal"
    >
      <template #modal-title>
        <span>Telegram Monitor: {{ historyParams?.author?.value }}</span>
      </template>

      <div class="main-tabs">
        <b-tabs content-class="p-10 m-t-20">
          <b-tab :title="$t('comment_search.group')" active @click="selectTab(false)">
            <b-container>
              <b-row class="align-items-center justify-content-center">
                <b-col cols="3">{{ $t(`comment_search.showPeriod`) }}:</b-col>
                <b-col cols="12" md="3">
                  <date-picker
                      v-model="selectedPeriodFrom"
                      type="date"
                      format="DD.MM.YYYY"
                      lang="ua"
                      :disabled="allPeriod"
                      :placeholder="$t(`comment_search.start_date`)"
                      :editable="false" />
                </b-col>
                <b-col cols="12" md="3">
                  <date-picker
                      v-model="selectedPeriodTo"
                      type="date"
                      :disabled="allPeriod"
                      :placeholder="$t(`comment_search.end_date`)"
                      format="DD.MM.YYYY"
                      :editable="false" />
                </b-col>
                <b-col cols="12" md="3" class="border-checkbox-section">
                  <div class="border-checkbox-group border-checkbox-group-primary">
                    <input
                        type="checkbox"
                        class="border-checkbox"
                        id="all_period"
                        v-model="allPeriod"
                    >
                    <label
                        for="all_period"
                        class="border-checkbox-label"
                    >
                      {{ $t('comment_search.full_date') }}
                    </label>
                  </div>
                </b-col>
                <div class="d-flex justify-content-center m-t-20">
                  <button class="btn btn-primary w-25" @click="getCountComments">
                    {{ $t(`comment_search.search`) }}
                  </button>
                </div>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab :title="$t('comment_search.quickSearch')" @click="selectTab(true)">
            <b-container>
              <b-row class="align-items-center m-b-20">
                <b-col cols="3">{{ $t(`comment_search.searchQuery`) }}</b-col>
                <b-col>
                  <b-form-input v-model="searchQuery" />
                </b-col>
              </b-row>

              <b-row  class="align-items-center m-b-20">
                <b-col cols="3">{{ $t(`comment_search.groupName`) }}</b-col>
                <b-col>
                  <b-form-input v-model="groupName" />
                </b-col>
              </b-row>
            </b-container>

            <b-container>
              <b-row class="align-items-center">
                <b-col cols="3">{{ $t(`comment_search.showPeriod`) }}:</b-col>
                <b-col cols="12" md="3">
                  <date-picker
                      v-model="selectedPeriodFrom"
                      type="date"
                      :disabled="allPeriod"
                      format="DD.MM.YYYY"
                      lang="ua"
                      :placeholder="$t(`comment_search.start_date`)"
                      :editable="false" />
                </b-col>
                <b-col cols="12" md="3">
                  <date-picker
                      v-model="selectedPeriodTo"
                      type="date"
                      :disabled="allPeriod"
                      :placeholder="$t(`comment_search.end_date`)"
                      format="DD.MM.YYYY"
                      :editable="false" />
                </b-col>
                <b-col cols="12" md="3" class="border-checkbox-section">
                  <div class="border-checkbox-group border-checkbox-group-primary">
                    <input
                        type="checkbox"
                        class="border-checkbox"
                        id="all_period"
                        v-model="allPeriod"
                    >
                    <label
                        for="all_period"
                        class="border-checkbox-label"
                    >
                      {{ $t('comment_search.full_date') }}
                    </label>
                  </div>
                </b-col>
                <div class="d-flex justify-content-center m-t-20">
                  <span id="tooltip-button-quickSearch" class="w-25">
                    <button
                        v-if="!searchQuery.length && !groupName.length"
                        :content="$t(`comment_search.quick_search_error_empty_fields`)"
                        v-tippy="{ placement : 'top',  arrow: true }"
                        class="btn btn-primary w-100"
                    >
                    {{ $t(`comment_search.search`) }}
                  </button>

                    <button
                        v-else
                        class="btn btn-primary w-100"
                        @click="getCountComments"
                    >
                    {{ $t(`comment_search.search`) }}
                  </button>
                  </span>
                </div>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
      </div>

      <preview-skeleton v-if="!isLoaded && isViewSkeleton" />

      <div v-if="isLoaded && !telegramComment" class="m-t-20">
        <div>{{ $t(`comment_search.countComments`) }}: 0</div>
      </div>

      <div
          v-if="isLoaded && telegramComment && !isModelExistRecord"
          class="m-t-20"
      >
        <div v-for="commentGroup in commentGroups">
          <div>{{ $t(`comment_search.countComments`) }}: {{ commentGroup.count }}</div>
          <div class="d-flex">
            <span class="p-r-10">{{ $t(`comment_search.periodFrom`) }}:</span>
            <span v-if="typeof historyParams.period_from !== 'undefined' && typeof historyParams.period_to !== 'undefined'">
                <span v-if="typeof historyParams.period_from !== 'undefined'">
                  {{ moment(historyParams.period_from, 'X').format('DD MMM YYYY') }} -
                </span>
                <span v-else>sxsx</span>
                <span v-if="typeof historyParams.period_to !== 'undefined'">
                  {{ moment(historyParams.period_to, 'X').format('DD MMM YYYY') }}
                </span>
                <span v-else>{{ $t('comment_search.full_date')}}</span>
              </span>
            <span v-else>{{ $t('comment_search.full_date')}}</span>
          </div>

          <table class="table table-hover table-responsive-sm">
            <thead>
            <th>{{ $t('ui.group') }}</th>
            <th>{{ $t('ui.period') }}</th>
            <th>{{ $t('ui.count') }}</th>
            </thead>
            <tbody>
            <tr v-for="group in commentGroup.groups" :key="group.id">
              <td>
                <div class="d-flex">
                  <div class="col-12 col-lg-3 border-checkbox-section">
                    <div class="border-checkbox-group border-checkbox-group-primary">
                      <input
                          :disabled="!group.count"
                          type="checkbox"
                          class="border-checkbox"
                          :id="'checkbox-' + group.id" v-model="groups"
                          :key="group.id" :value="{'id': group.id,'name': group.name,'count': group.count}"
                      >
                      <label
                          :for="'checkbox-' + group.id"
                          class="border-checkbox-label"
                      >
                        {{ group.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <span v-if="group.count">
                  {{ moment(group.period_from, 'X').format('DD MMMM YYYY') }} -
                  {{ moment(group.period_to, 'X').format('DD MMMM YYYY') }}
                </span>
              </td>
              <td>{{ group.count || '0'}}</td>
            </tr>
            </tbody>
          </table>
        </div>

        {{ $t(`comment_search.countSelectedGroup`) }}: {{ groups.length }}

        <div class="d-flex justify-content-center">
          <span class="w-25">
            <b-button
                v-if="!groups.length"
                v-tippy="{ placement : 'top',  arrow: true, content: $t('comment_search.hint.show_comment_preview')}"
                class="w-100"
                variant="primary"
            >
              {{ $t('search.open') }}
            </b-button>

            <b-button
                v-if="groups.length"
                class="w-100"
                variant="primary"
                v-b-modal.modal-multi-3
            >
              {{ $t('search.open') }}
            </b-button>
            <span v-if="groups.length" class="text-secondary p-t-5 d-block">
              {{ $t('comment_search.money_written') }} {{ search_rates?.defaults?.offline_result_price * groups.length}}
            </span>
          </span>
        </div>
      </div>

      <div v-if="isLoaded && !telegramComment" class="d-flex justify-content-center m-t-20 bg-light">
        {{ $t('result.nothing_found') }}
      </div>
    </b-modal>

    <comment-main-search :data="searchData" :groups="groups"/>

    <b-modal v-model="isModelExistRecord" centered :title="$t('search.query_exist')" hide-footer has-modal-card
             trap-focus :destroy-on-hide="false" aria-role="dialog"
             aria-label="Example Modal" aria-modal
    >
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <div class="d-flex">
              <span class="p-r-10">{{ $t(`comment_search.periodFrom`) }}:</span>
              <span v-if="typeof historyParams.period_from !== 'undefined' && typeof historyParams.period_to !== 'undefined'">
                <span v-if="typeof historyParams.period_from !== 'undefined'">
                  {{ moment(historyParams.period_from, 'X').format('DD MMM YYYY') }} -
                </span>
                <span v-else>sxsx</span>
                <span v-if="typeof historyParams.period_to !== 'undefined'">
                  {{ moment(historyParams.period_to, 'X').format('DD MMM YYYY') }}
                </span>
                <span v-else>{{ $t('comment_search.full_date')}}</span>
              </span>
              <span v-else>{{ $t('comment_search.full_date')}}</span>
            </div>

            <b-button variant="primary" class="mt-3" @click="remoteComments()">{{ $t('search.update') }}</b-button>
            <b-button variant="secondary" class="mt-2" @click="localComments()">{{ $t('search.open') }}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import CommentMainSearch from "@/components/search/comments/commentMainSearch.vue";
import {Result} from "@/models/result";
import telegramMonitor from "@/mixins/telegramMonitor";
import PreviewSkeleton from "@/components/search/comments/components/preview_skeleton.vue";

export default {
  name: "SearchComment",

  mixins: [telegramMonitor],

  setup() {
    return {
      moment
    }
  },

  components: {
    CommentMainSearch,
    DatePicker,
    PreviewSkeleton,
  },

  props: ['recordId', 'result'],

  data() {
    return {
      isModelExistRecord: false,
      isLoaded: false,
      isViewSkeleton: false,
      selectedPeriodFrom: '',
      selectedPeriodTo: '',
      groups: [],
      searchQuery: '',
      groupName: '',
      parentKey: '',
      searchData: {},
      searchMainAttr: {},
      isFullParams: false,
      allPeriod: false,
      commentGroups: [],
    }
  },

  computed: {
    ...mapGetters('search_comment', ['telegramComment', 'historyParams']),
    ...mapGetters('search_rates', ['search_rates', 'servicesList']),
  },

  methods: {
    ...mapMutations('search_comment', ['setClearTelegramComments']),

    selectTab(isTab) {
      this.isFullParams = isTab;
      this.clearDataForm();
    },

    clearDataForm() {
      this.selectedPeriodFrom = '';
      this.selectedPeriodTo = '';
      this.searchQuery = '';
      this.groupName = '';
    },

    async firstOpen() {
      moment.locale(this.locale());

      this.getSearchAttr();

      let firstData = {... this.searchData};
      firstData.main = true;

      this.selectedPeriodFrom = moment().subtract(1, 'year').startOf('day');
      this.selectedPeriodTo = moment().startOf('day');
      this.prepareBodyRequest();

      this.parentKey = this.generatePreviewDbKeyWithParent(firstData, 'preview');

      await this.$store.dispatch('search_comment/getLocalPreviewTelegramGroupComments', this.parentKey)

      if (!Object.keys(this.telegramComment).length) {
        await this.remoteComments();
        this.groupComments();
        return;
      }

      if (Object.keys(this.telegramComment).length) {
        this.isModelExistRecord = true;
      }

      this.groupComments();

      this.clearDataForm();

      await this.$store.dispatch('search_rates/getSearchRates');
    },

    async getCountComments() {
      this.groups = [];
      this.prepareBodyRequest();

      this.parentKey = this.generatePreviewDbKeyWithParent(this.searchData, 'preview');
      const response = await this.$store.dispatch('search_comment/getLocalPreviewTelegramGroupComments', this.parentKey);

      if (!Object.keys(response).length) {
        this.clearData();

        await this.remoteComments();
        this.groupComments();
        return;
      }

      if (Object.keys(response).length) {
        this.isModelExistRecord = true;
      }

      this.groupComments();
    },


    groupComments() {
      this.commentGroups = [];

      if (this.telegramComment.search_results !== null) {
        this.telegramComment.search_results.forEach(group => {
          group.groups.forEach(item2 => {
            const match = this.telegramComment.relative_results.groups.find(item1 => item1.id === item2.id);

            let name = {};

            if (match.data) {
              name = match.data.find(name => name.param === 'name');
            } else {
              name.value= match.name;
            }

            delete match.data;
            match.name = name.value;
            if (match) {
              Object.assign(item2, match);
            }
          });

          this.telegramComment.relative_results.groups.forEach(item1 => {
            const exists = group.groups.some(item2 => item2.id === item1.id);
            if (!exists) {
              let name = item1.data.find(name => name.param === 'name');
              delete item1.data;
              item1.name = name.value;

              group.groups.push(item1);
            }
          });
        });

        this.commentGroups = this.telegramComment.search_results;
      } else {
        this.telegramComment.relative_results.groups.forEach(item1 => {
          let name = item1.data.find(name => name.param === 'name');
          delete item1.data;
          item1.name = name.value;
          item1.count = 0;
        });

        this.commentGroups.push(this.telegramComment.relative_results);
      }
    },

    async remoteComments() {
      this.isModelExistRecord = false;
      await Result.deleteResult(this.parentKey);

      this.isLoaded = false;
      this.isViewSkeleton = true;

      let response = await this.$store.dispatch('search_comment/getTelegramComments', this.searchData);

      this.isViewSkeleton = false;

      if (!response.length) {
        this.isLoaded = true;
        this.isViewSkeleton = false;
        return;
      }

      let payload = {
        cache_search_enabled: true,
        online_search_enabled: false,
        value: this.searchData.author.value,
        sources: ['Telegram_scr_2023'],
        childKey: this.parentKey,
        requestBody: this.searchData,
      };

      await this.$store.dispatch('contacts/cacheWithResultsChild', {
        data: response,
        type: 'telegram_monitor_preview',
        parentKey: this.$store.getters["contacts/meta"].key,
        payload
      });

      this.isLoaded = true;
    },

    getSearchAttr() {
      this.searchMainAttr = {
        param: this.result.param,
        value: this.result.value
      };
    },

    prepareBodyRequest() {
      const data = {
        'author': this.searchMainAttr,
      }

      if (this.isFullParams) {
        if (this.groupName) data['group'] = {"param": "name", "value": this.groupName};
        if (this.searchQuery) data['search_query'] = this.searchQuery;
      }

      if (!this.allPeriod) {
        if (this.selectedPeriodFrom) {
          data['period_from'] = moment(this.selectedPeriodFrom).format("X");
        }

        if (this.selectedPeriodTo) {
          data['period_to'] = moment(this.selectedPeriodTo).add(23, 'hour').add(59, 'minute').add(59, 'second').format("X");
        }
      }

      this.searchData = data;
    },

    localComments() {
      this.isModelExistRecord = false;
      this.isViewSkeleton = false;
      this.isLoaded = true;
    },

    toCamelCase(code) {
      return code.replace(/([a-zA-Z0-9]+)_([a-zA-Z0-9])/g, (_, prefix, nextChar) => {
        return prefix + nextChar.toUpperCase();
      });
    },

    clearData() {
      this.setClearTelegramComments()
      this.isLoaded = false;
    },

    closeModal() {
      this.clearData();
      Object.assign(this.$data, this.$options.data.call(this));
    },

    openModal() {
      this.firstOpen()
    },

    locale() {
      return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale
    },
  }
}
</script>

<style scoped>
.main-tabs >>> .comment-tab {
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #37474f;
  border-radius: 0;
  color: #37474f;
  width: 200px;
  text-align: center;
}

.main-tabs >>> .comment-tab.active, .main-tabs >>> .comment-tab.active:hover {
  color: #0275d8;
  text-align: center;
  border-bottom: 2px solid #0275d8;
}
</style>