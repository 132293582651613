<template>
  <b-modal
      content-class="h-100"
      id="modal-multi-3"
      size="xl"
      title="Third Modal"
      ok-only
      @show="openModal"
      scrollable
  >
    <template #modal-title>
      <span>Telegram Monitor: {{ data.author.value }}</span>
    </template>

    <template #modal-header-close>
      <div class="bg-dark position-absolute end-0 top-0 m-10 p-l-10 p-r-10 rounded">
        <b-icon variant="white" icon="arrow-left-short" font-scale="3" aria-hidden="true" />
      </div>
    </template>

    <div class="main-comment">
      <b-tabs card vertical>
        <b-tab
            @click="getComments(group.id)"
            v-for="group in groups"
            title-link-class="comment-tab"
        >
          <template #title>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  {{ group.name }}
                  <b-icon
                      v-if="!localDataStatus[group.id] && activeTab !== group.id"
                      icon="cloud-download-fill"
                      class="m-l-10"
                      aria-hidden="true"
                  />
                  <b-spinner
                      v-if="!isLoaded && activeTab === group.id"
                      type="border"
                      small
                  />
                  <div class="badge bg-secondary m-l-5 m-r-10 rounded" v-if="!localDataStatus[group.id]">
                    {{ search_rates?.defaults?.offline_result_price }} K
                  </div>
                </div>
                <div>
                  <span class="text-muted">{{ $t('comment_search.content_type') }}:</span>
                  <span class="badge bg-secondary m-l-5 m-r-10 rounded">group</span>
                  <span v-if="activeTab === group.id">
                    <span class="text-muted">{{ $t('comment_search.count') }}:</span>
                    <b-badge variant="primary" class="m-l-5 rounded">{{ group.count }}</b-badge>
                  </span>
                </div>
              </div>
            </div>
          </template>
          <b-card-text>
            <div v-if="!isLoaded">
               <b-skeleton class="m-t-10" height="3rem" width="100%" v-for="skeliton in structureComments.length"/>
            </div>

            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item" v-if="isLoaded && telegramGroupComments"
                   v-for="comment in structureComments">
                <h2 class="accordion-header" :id="'heading-' + comment.id">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          aria-expanded="false"
                          :data-bs-target="'#collapse-' + comment.id" :aria-controls="'collapse-' + comment.id">
                    <div class="text-truncate d-flex align-items-center" style="max-width: 600px;">
                      <b-icon v-if="comment.children.length" class="limitIcon field m-r-10" icon="chat-right-text-fill" size="is-medium"/>
                      <b-icon v-if="!comment.children.length" class="limitIcon field m-r-10" icon="card-text" size="is-medium"/>

                      <span v-html="randomCommentCurrentAuthor(comment)"></span>
                    </div>
                  </button>
                </h2>
                <div :id="'collapse-' + comment.id" class="accordion-collapse collapse"
                     :aria-labelledby="'heading-' + comment.id">
                  <div class="p-10 accordion-body">
                    <CommentTree :comment="comment"/>
                  </div>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-end w-100 align-items-center">
        <comment-search-pagination
            v-if="structureComments.length"
            :loadedStatus="isLoaded"
            :per-page="10"
            :total-records="matches_count"
            @page-changed="nextPage($event)"
        />
      </div>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ImageProfile from "@/components/ImageProfile.vue";
import CommentTree from "@/components/search/comments/CommentTree.vue";
import CommentSearchPagination from "@/components/search/CommentSearchPagination.vue";
import {Result} from '@/models/result'
import dbKeyTelegramMonitor from "@/mixins/telegramMonitor";
import moment from "moment/moment";

export default {
  name: "commentMainSearch",
  components: {CommentTree, ImageProfile, CommentSearchPagination},

  mixins: [dbKeyTelegramMonitor],

  props: ['data', 'groups'],

  data() {
    return {
      activeTab: null,
      isLoaded: true,
      structureComments: [],
      localDataStatus: [],
      matches_count: 0,
      offset: 0,
      request_id: 0,
      source_id: 0,
      currentPage: 0,
    }
  },

  computed: {
    ...mapGetters('search_comment', ['telegramGroupComments']),
    ...mapGetters('search_rates', ['search_rates', 'servicesList']),
  },

  methods: {
    ...mapMutations('search_comment', ['setLocalTelegramComments']),

    async checkLocalData(groupId, data) {
      data['group_id'] = [groupId];

      let key = this.generatePreviewDbKeyWithParent(data, 'full');
      let res = await Result.getResultByKey(key);

      this.$set(this.localDataStatus, groupId, typeof res !== 'undefined');
    },

    async nextPage(currentPage) {
      if (currentPage === this.currentPage) return;
      this.currentPage = currentPage;

      this.telegramGroupComments.offset = this.calculateOffset(currentPage);

      if (typeof this.telegramGroupComments.offset === 'boolean') {
        await this.getComments(this.data['group_id'][0]);
        return;
      }

      let data = {
        body: {limit: 20, offset: this.telegramGroupComments.offset},
        query: {request_id: this.request_id, source_id: this.source_id}
      };

      let childKey = this.generatePreviewDbKeyWithParent(this.data, 'full');

      let dbResults = await Result.getResultByKey(childKey);

      if (typeof dbResults.result.results[0].search_results[0].commentPaginations === 'undefined') {
        dbResults.result.results[0].search_results[0].commentPaginations = [];
      }

      if (typeof dbResults.result.results[0].search_results[0].commentPaginations[currentPage] === 'undefined') {
        this.isLoaded = false;

        await this.$store.dispatch('search_comment/getTelegramGroupCommentsPaginate', data);
        this.buildTreeComments();
        dbResults.result.results[0].search_results[0].commentPaginations[currentPage] = this.structureComments;
        await Result.updateResult(dbResults.key, dbResults.result);
        this.isLoaded = true;
      }

      this.structureComments = dbResults.result.results[0].search_results[0].commentPaginations[currentPage];
    },

    calculateOffset(currentPage) {
      let offset = false;

      if (currentPage > 1) {
        offset = (currentPage * 10) - 10;
      }

      return offset;
    },

    openModal() {
      this.$store.dispatch('search_rates/getSearchRates');
      this.isLoaded = false;
      this.activeTab = null;
      this.structureComments = [];

      let group = this.groups.find(() => true);
      this.getComments(group.id)
    },

    closeModal() {
      this.$bvModal.hide('modal-multi-3');
    },

    async getComments(groupId) {
      this.data.author_id = await this.getUserIds();

      this.groups.forEach(group => {
        this.checkLocalData(group.id, this.data);
      });

      this.data.group_id = [groupId];

      let childKey = this.generatePreviewDbKeyWithParent(this.data, 'full');

      let dbResults = await Result.getResultByKey(childKey);

      this.activeTab = groupId;
      this.data.group_id = [groupId];

      if (typeof dbResults === 'undefined') {
        this.isLoaded = false;
        await this.getRemoteData(groupId);
      }

      if (typeof dbResults !== 'undefined') {
        await this.setLocalTelegramComments(dbResults.result.results[0]);
        this.structureComments = dbResults.result.results[0].search_results[0].comments;

        this.isLoaded = true;
      }

      this.matches_count = this.telegramGroupComments.matches_count;
      this.offset = this.telegramGroupComments.offset;
      this.request_id = this.telegramGroupComments.request_id;
      this.source_id = this.telegramGroupComments.source_id;
    },

    async getUserIds() {
      let dbUsers = await Result.getResult(Number(this.$route.params.id));

      return await dbUsers.result.results.filter(item => {
        return item.source === "Telegram_scr_2023";
      }).flatMap(item => {
        return item.search_results.map(subItem => subItem.id);
      });
    },

    async getRemoteData(groupId) {
      let dataResponse = await this.$store.dispatch('search_comment/getTelegramGroupComments', this.data);
      this.buildTreeComments()
      this.isLoaded = true;

      dataResponse[0].search_results = [this.getGroupById(groupId)]
      dataResponse[0].search_results[0]['comments'] = this.structureComments;
      let childKey = this.generatePreviewDbKeyWithParent(this.data, 'full');

      let payload = {
        cache_search_enabled: true,
        online_search_enabled: false,
        value: this.data.author.value,
        sources: ['Telegram_scr_2023'],
        childKey: childKey,
        countResult: dataResponse[0].total_count,
        requestBody: this.data,
      }

      dataResponse[0]['param'] = this.data.author.param;

      await this.$store.dispatch('contacts/cacheWithResultsChild', {
        data: dataResponse,
        type: 'telegram_monitor',
        parentKey: this.$store.getters["contacts/meta"].key,
        payload
      });
    },

    toCamelCase(code) {
      return code.replace(/([a-zA-Z0-9]+)_([a-zA-Z0-9])/g, (_, prefix, nextChar) => {
        return prefix + nextChar.toUpperCase();
      });
    },

    getAuthorName(authorId) {
      return this.telegramGroupComments.relative_results.authors.find(author => author.id === authorId)
    },

    getGroupById(groupId) {
      return this.telegramGroupComments.relative_results.groups.find(group => group.id === groupId)
    },

    randomCommentCurrentAuthor(comments) {
      let author = this.telegramGroupComments.relative_results.authors.find(
          user => user.data.some(item => item.value.toLowerCase() === this.data.author.value.toLowerCase())
      );

      let authorComment = {}
      if (comments.author_id === author.id) {
        authorComment = comments;
      }

      if (!Object.keys(authorComment).length) {
        authorComment = comments.children.find(comment => comment.author_id === author.id);
      }

      return `${authorComment?.text} <span class="m-l-10 fst-italic text-muted">${moment.unix(authorComment.date).format('DD.MM.YYYY')} ${moment.unix(authorComment.date).format('HH:mm')}</span>`;
    },

    buildTreeComments() {
      const map = {};

      this.telegramGroupComments.search_results.forEach(item => {
        item.children = [];
        map[item.id] = item;
      });

      const result = [];

      const calculateLevel = (item, currentLevel = 0) => {
        item.level = currentLevel;
        item.author = this.getAuthorName(item.author_id);
        item.photos = item.author.photos;
        item.data = item.author.data;

        item.children.forEach(child => {
          calculateLevel(child, currentLevel + 1);
        });
      };

      this.telegramGroupComments.search_results.forEach(item => {
        if (item.parent_id && map[item.parent_id]) {
          map[item.parent_id].children.push(item);
        } else if (item.reply_to_id && map[item.reply_to_id]) {
          map[item.reply_to_id].children.push(item);
        } else {
          result.push(item);
        }
      });

      result.forEach(rootComment => {
        calculateLevel(rootComment);
      });

      this.structureComments = result;
    }
  }
}
</script>

<style scoped>
.main-comment >>> .comment-tab {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 4px solid #ffffff;
  border-radius: 0;
  color: #37474f;
}

.main-comment >>> .comment-tab.active {
  border-left: 4px solid #4099ff;
  color: #4099ff;
}
</style>