<template>
  <div
      :style="
      comment.level === 0
      ? { padding: '20px', border: '1px solid #000', borderRadius: '10px', marginBottom: '20px', marginTop: '20px', paddingTop: '20px' }
      : {paddingLeft: '40px', paddingTop: '20px'}"
  >
    <div v-for="data in comment.data.data" style="display: flex;">
      <div style="width: 150px;"><strong>{{data.param}}</strong></div>
      <div>{{data.value}}</div>
    </div>

    <div style="display: flex;">
      <div style="width: 150px;"><strong>Date</strong></div>
      <div>{{ moment.unix(comment.date).format('DD.MM.YYYY HH:mm') }}</div>
    </div>

    <div style="display: flex;">
      <div style="width: 150px;"><strong>comment</strong></div>
      <div>{{ comment.text }}</div>
    </div>

    <CommentTree
        v-for="child in comment.children"
        :key="child.id"
        :comment="child"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CommentTree",
  computed: {
    moment() {
      return moment
    }
  },

  props: {
    comment: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped>

</style>