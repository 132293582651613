<template>
  <div class="tree-node">
    <div class="d-flex align-items-center p-10">
      <div class="m-r-10 d-flex align-items-center position-relative" :class="`m-l-${comment.level}`" v-if="comment.level">
        <b-icon v-if="comment.level === 1" icon="reply-fill" class="h5 m-t-5" flip-v flip-h/>
        <b-icon v-if="comment.level > 1" icon="reply-all-fill" class="h5 m-t-5" flip-v flip-h/>
      </div>
<!--      <photo-fragment :photo="comment.author.photos[0]" :is-child="false" />-->
      <image-profile
          size="35px"
          v-if="comment.author.photos.length"
          :payload="comment.author.photos[0].image"
          @preview="openModal()"
      />
      <img
          v-if="!comment.author.photos.length"
          width="30"
          :src="`${prefix}images/no_photo_` + `${$root.$i18n.locale}` + `.png`"
          alt="no_photo"
      />
      <div>
        <div class="d-flex align-items-center">
          <div class="fw-bold mr-auto m-l-10 m-r-10">
            {{ getFieldsInArray(comment.author.data, 'username, name') }}
          </div>
          <div class="fst-italic text-muted">
            <span class="badge bg-secondary rounded">{{ $t(`comment_search.contentType.${comment.type}`) }}</span>
            {{ comment.date | moment('DD.MM.YYYY') }} {{ comment.date | moment('HH:mm') }}
          </div>
        </div>

        <div class="m-l-10">
          {{ comment.text }}
        </div>
      </div>
    </div>
    <div
        v-if="comment.children && comment.children.length"
        class="node-children m-l-10"
    >
      <CommentTree
          v-for="child in comment.children"
          :key="child.id"
          :comment="child"
      />
    </div>

    <b-modal v-model="isOpenModal"
             v-if="comment.author.photos.length"
             size="xl"
             width="960"
             centered
             scroll="keep"
             hide-footer>
      <face-inner :img-base="{}"  :meta="{}" :photo="comment.author.photos[0]"/>
    </b-modal>
  </div>
</template>

<script>
import ImageProfile from "@/components/ImageProfile.vue";
import env from "@/configs/env.json";
import FaceInner from "@/components/results/FaceInner.vue";
import PhotoFragment from "@/components/PhotoFragment.vue";

export default {
  name: "CommentTree",
  components: {PhotoFragment, FaceInner, ImageProfile},

  props: {
    comment: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      prefix: env.prefix,
      isOpenModal: false,
    }
  },

  methods: {
    openModal() {
      this.isOpenModal = !this.isOpenModal;
    },

    getFieldsInArray(array, fields) {
      const fieldList = fields.split(',').map(field => field.trim());
      const values = fieldList.map((field, index) => {
        const foundItem = array.find(item => item.param === field);
        if (foundItem) {
          return index === 0 ? foundItem.value : `(${foundItem.value})`;
        }
        return null;
      }).filter(value => value !== null);

      return values.join(' ');
    },
  }
}
</script>

<style scoped>

</style>