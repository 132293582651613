<template>
  <tr class="child-result" >
    <td @click="openChild(child, child.keyVal)" class="d-flex align-items-center padding">
      <div class="d-inline-block result-item-photo-container m-r-10" style="display: flex">
        <div class="d-inline-block result-item-photo-container m-r-10">
          <image-profile :size="'64px'" v-if="child.photos"
                         :payload="child.imageFromResults" />
          <img :src="`${prefix}images/no_photo_`+`${$root.$i18n.locale}`+`.png`" v-else-if="result.loaded">
          <img :src="`${prefix}images/user-found.png`" v-else>
          <span class="badge badge-primary" :content="$t('settings.photos_includes', {count: child.photosCount })" v-tippy="{ placement : 'top',  arrow: true,  }" v-if="child.photosCount">{{ child.photosCount }}</span>
        </div>
      </div>
      <div style="display: flex;flex-direction: column; justify-content: center">
        <h6 v-if="!isUndefined(child.param)">{{ child.name || $t('child_contact.no_name') }}</h6>

        <p v-if="Object.keys(child.payload.requestBody).length > 1" class="text-muted m-b-0" style="white-space: break-spaces">{{ $t("search_list_item.options") }}:
          <span v-if="child.payload.requestBody.period_from || child.payload.requestBody.period_to">
            {{ $t('comment_search.periodFrom') }}:
            <span v-if="child.payload.requestBody.period_from">
            {{ Number(child.payload.requestBody.period_from) | moment('DD.MM.YYYY') }}
            <span v-if="!child.payload.requestBody.period_to"> - {{ $t('comment_search.not_date') }}</span>
          </span>
          <span v-if="child.payload.requestBody.period_from && child.payload.requestBody.period_to">-</span>
          <span v-if="child.payload.requestBody.period_to">
            <span v-if="!child.payload.requestBody.period_from">{{ $t('comment_search.not_date') }} - </span>
            {{ Number(child.payload.requestBody.period_to) | moment('DD.MM.YYYY') }}
          </span>
          </span>

          <span v-if="child.payload.requestBody.search_query">
            {{ $t('comment_search.searchQueryOption') }}:
            {{ child.payload.requestBody.search_query }}
          </span>
        </p>
      </div>
    </td>
    <td class="padding"></td>
    <td class="padding" @click="openChild(child, child.keyVal)">{{resultsCount || ''}}</td>

    <td class="padding d-none d-lg-table-cell" @click="resultsCount && openChild(child, child.keyVal)">
      <div style="display: flex; flex-direction: column">
        <div class="d-none d-lg-inline">{{ child.date | moment('DD.MM.YYYY') }}</div>
        <div class="d-none d-lg-inline">{{ child.date | moment('HH:mm') }}</div>
      </div>
    </td>

    <td class="padding d-flex d-lg-table-cell" >
      <div class="d-inline d-lg-none"> {{ child.date | moment('DD.MM.YYYY, HH:mm') }}</div>
      <search-cost
          v-if="typeof child.totalCost !== 'undefined'"
          style="margin-top: 2px"
          :header='header'
          :cost="child.cost"
          :total-cost="child.totalCost"
          :ident="child.keyVal"
      />
    </td>

    <td class="text-right padding">
      <div class="d-none d-lg-block text-right" style="margin-left: 12px">
        <a href="#" v-b-modal="`delete_${child.keyVal}`" data-bs-toggle="modal" v-show="result.loaded" :content="$t('modal.deleteResult')" v-tippy="{ placement : 'top',  arrow: true }"><i
            class="feather icon-trash-2 f-w-600 f-16 text-c-red"></i>
        </a>
      </div>
      <div class="btn-group d-lg-none" style="width: 100%;">
        <a href="#" class="btn btn-danger" v-b-modal="`delete_${child.keyVal}`" data-bs-toggle="modal" v-show="result.loaded" :content="$t('result.edit_result')" v-tippy="{ placement : 'top',  arrow: true }"><i class="feather icon-trash-2"></i></a>
      </div>
    </td>

    <b-modal :id="`delete_${child.keyVal}`" :ref="`delete-child-modal`" hide-footer :title="$t('search_list_item.the_result_will_be_deleted_proceed')" centered>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" block @click="deleteSearchItem(child)">{{$t('modal.actionDelete') }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="hideModal">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>
  </tr>
</template>

<script>
import ImageProfile from "@/components/ImageProfile.vue";
import SearchCost from "@/components/billing/searchCost.vue";
import env from "@/configs/env.json";
import { isUndefined } from "underscore";
import {Result} from '@/models/result'

export default {
  name: "SearchListItemChildTelegramMonitor" ,

  components: {SearchCost, ImageProfile},

  props: ['result', 'child'],

  data() {
    return {
      childResult: {},
      name: '' ,
      header: false,
      prefix: env.prefix,
      isUndefined,
    }
  },

  computed: {
    resultsCount(){
      return this.child.payload.countResult
    },
  },

  methods: {
    async openChild(child, keyVal) {
      let dbComments = await Result.getResultByKey(keyVal);
      await this.$router.push({name: 'TelegramMonitor', params: {id: dbComments.id}})
    },

    deleteSearchItem(child) {
      let data = [];
      data['result'] = this.result;
      data['child'] = child;

      this.$store.dispatch('contacts/removeChild', data)
      this.result.children.forEach((item) => {
        this.$store.dispatch('search_comment/removeChildMainSearch', item.keyVal)
      });
    },

    hideModal() {
      this.$refs['delete-child-modal'].hide();
    },
  },
}
</script>

<style scoped>

</style>