import Vue from "vue";
import HtmlTemplate from "@/components/export/HtmlTemplate.vue";
import i18n from "@/i18n";
import store from '@/store'
import jsPDF from 'jspdf';

export async function generateHtml(full, meta, token) {
    const HtmlTemplateComponent = Vue.extend(HtmlTemplate);

    const instance = new HtmlTemplateComponent({
        propsData: { full, meta, token },
        i18n,
        store,
    });

    const container = document.createElement("div");
    instance.$mount(container);

    await new Promise((resolve) => {
        instance.$on('ready', () => resolve());
    });

    const html = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>HTML</title>
      ${instance.$data.style}
    </head>
    <body>
      ${instance.$el.outerHTML}
    </body>
    </html>
  `;

    instance.$destroy();

    return html;
}

export async function downloadHtml(full, meta, token) {
    const html = await generateHtml(full, meta, token);

    const doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        hotfixes: ['px_scaling'],
    });

    doc.html(html, {
        callback: function (doc) {
            doc.save();
        }
    });

    const blob = new Blob([html], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = 'filename';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    return true;
}

export function selectedLocale() {
    if (i18n.locale === 'ua') {
        return 'uk'
    } else {
        return i18n.locale
    }
}