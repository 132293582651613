export default {
    data() {
        return {
            properties: ['search_query', 'group', 'content_type', 'period_from', 'period_to', 'group_id', 'main', 'author_id']
        };
    },

    methods: {
        generatePreviewDbKey(dataFields, type) {
            return `type_telegram_monitor_${type}_${this.getChildKey(dataFields)}`;
        },

        generatePreviewDbKeyWithParent(dataFields, type, parentKey = this.$store.getters["contacts/meta"].key) {
            return `${parentKey}_type_telegram_monitor_${type}_${this.getChildKey(dataFields)}`;
        },

        getChildKey(data) {
            return this.properties
                .map(prop => {
                    if ((prop === 'group' && data[prop])) {
                        return `${this.toCamelCase(prop)}_${data[prop].value}`;
                    }
                    if (prop === 'group_id' && data[prop]) {
                        return `${this.toCamelCase(prop)}_${data[prop][0]}`;
                    }

                    if (prop === 'author_id' && data[prop]) {
                        let author_id = '';
                        data[prop].forEach(item => {
                            author_id += `${this.toCamelCase(prop)}_${author_id}`;
                        });
                    }

                    return data[prop] !== undefined ? `${this.toCamelCase(prop)}_${data[prop]}` : undefined;
                })
                .filter(value => value !== undefined)
                .join('_');
        },
    }
};