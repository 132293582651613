<template>
  <div class="main-comment">
    <div class="d-grid d-lg-flex justify-content-lg-end btn-save">
      <b-dropdown right :disabled="isDownloadHtml" id="dropdown-1" variant="primary" class="m-md-2 btn-primary">
        <template #button-content>
          <b-spinner small v-if="isDownloadHtml"></b-spinner>
          {{ $t('pdf.save_pdf') }}
        </template>
        <b-dropdown-item @click="createHtml()">
          <i class="feather icon-download"></i>
          <span style="padding-left: 4px;">{{ $t("pdf.save_pdf") }} цю групу</span>
        </b-dropdown-item>
        <b-dropdown-item @click="createHtml(true)">
          <i class="feather icon-download"></i>
          {{ $t("pdf.save_pdf") }} всі групи
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="d-block m-t-30">
      <b-card>
        <b-card-body>
          <div class="p-3">
            <div class="d-flex" v-if="meta.payload">
              <div class="p-r-10" style="font-weight: 600;">{{ $t("search_list_item.options") }}</div>

              <div class="p-r-5">{{ $t(`params.${meta.payload.requestBody.author.param}`) }}:</div>
              <div class="p-r-3">{{ meta.payload.requestBody.author.value}} |</div>

              <div class="p-l-5 p-r-5">{{ $t('comment_search.periodFrom') }}:</div>
              <div class="p-r-3">
                <span v-if="!meta.payload.requestBody.period_from && !meta.payload.requestBody.period_to">
                  {{ $t('comment_search.full_date')}}
                </span>
                <span v-if="meta.payload.requestBody.period_from">{{ Number(meta.payload.requestBody.period_from) | moment('DD.MM.YYYY')}} -</span>
                <span v-if="meta.payload.requestBody.period_to">{{ Number(meta.payload.requestBody.period_to) | moment('DD.MM.YYYY')}}</span>
                |
              </div>

              <div class="p-l-5 p-r-5" v-if="meta.payload.requestBody.search_query">{{ $t('comment_search.searchQueryOption') }}:</div>
              <div class="p-r-3" v-if="meta.payload.requestBody.search_query">{{ meta.payload.requestBody.search_query}} |</div>

              <div class="p-l-5 p-r-5" v-if="meta.payload.requestBody.group?.value">{{ $t('comment_search.searchGroupOption') }}:</div>
              <div class="p-r-3" v-if="meta.payload.requestBody.group?.value">{{meta.payload.requestBody.group.value}} |</div>
            </div>

            <div class="d-flex font-weight-bold" v-if="search_rates.defaults">
              <div style="font-weight: 600;">{{ $t('comment_search.search_result_costs')}}</div>:
              {{ search_rates?.defaults?.offline_result_price }}K
            </div>
            <b-skeleton v-if="!search_rates.defaults" class="m-t-10" height="1rem" width="400px" />
          </div>

          <div v-for="result in groups.search_results" :key="result.id" class="m-t-20 main-comment">
            <b-tabs card vertical>
              <b-tab
                  :disabled="!group.count"
                  @click="selectTab(group.id)"
                  v-for="group in result.groups"
                  title-link-class="comment-tab"
              >
                <template #title>
                  <b-skeleton v-if="!search_rates.defaults" class="m-t-10" height="3rem" width="250px"/>

                  <div v-if="search_rates.defaults" class="d-flex justify-content-between align-items-center">
                    <div>
                      <div class="d-flex justify-content-between align-items-center" :class="{'text-muted': !group.count}">
                        {{group.name}}
                                              <b-icon
                                                  v-if="!localDataStatus.find(item => item.groupId === group.id && item.status)"
                                                  icon="cloud-download-fill"
                                                  class="m-l-10"
                                                  aria-hidden="true"
                                              />
                        <div class="badge bg-secondary m-l-5 m-r-10 rounded" v-if="!localDataStatus.find(item => item.groupId === group.id && item.status)">
                          {{ search_rates?.defaults?.offline_result_price }} K
                        </div>
                                              <b-spinner
                                                  v-if="!isLoaded && activeTab === group.id"
                                                  type="border"
                                                  small
                        />
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="text-muted">{{ $t('comment_search.content_type') }}:</div>
                        <div class="badge bg-secondary m-l-5 m-r-10 rounded">group</div>
                        <div v-if="activeTab === group.id" class="d-flex align-items-center">
                            <div class="text-muted">{{ $t('comment_search.count') }}:</div>
                            <div class="badge bg-primary m-l-5 rounded">{{ group.count || 0}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                              <b-card-text>
                                <div v-if="!isLoaded">
                                  <b-skeleton class="m-t-10" height="3rem" width="100%" v-for="skeliton in 6"/>
                                </div>

                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                  <div class="accordion-item" v-if="isLoaded && structureComments.length"
                                       v-for="comment in structureComments">
                                    <h2 class="accordion-header" :id="'heading-' + comment.id">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                              aria-expanded="false"
                                              :data-bs-target="'#collapse-' + comment.id" :aria-controls="'collapse-' + comment.id">
                                        <div class="text-truncate d-flex align-items-center" style="max-width: 600px;">
                                          <b-icon v-if="comment.children.length" class="limitIcon field m-r-10" icon="chat-right-text-fill" size="is-medium"/>
                                          <b-icon v-if="!comment.children.length" class="limitIcon field m-r-10" icon="card-text" size="is-medium"/>

                                          <span v-html="randomCommentCurrentAuthor(comment)"></span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div :id="'collapse-' + comment.id" class="accordion-collapse collapse"
                                         :aria-labelledby="'heading-' + comment.id">
                                      <div class="p-10 accordion-body">
                                        <CommentTree :comment="comment"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="p-3">
                                  <comment-search-pagination
                                      class="position-absolute bottom-0 m-b-20 end-0 m-r-40"
                                      v-if="telegramGroupComments?.search_results?.length"
                                      :loadedStatus="isLoaded"
                                      :per-page="10"
                                      :total-records="matches_count"
                                      @page-changed="nextPage($event)"
                                  />
                                </div>
                              </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import CommentTree from "@/components/search/comments/CommentTree.vue";
import {Result} from '@/models/result'
import CommentSearchPagination from "@/components/search/CommentSearchPagination.vue";
import {mapGetters, mapMutations} from "vuex";
import savePdf from "@/mixins/savePdf";
import filters from "@/mixins/filters";
import imageMixin from "@/mixins/imageMixin";
import saveDoc from "@/mixins/saveDoc";
import i18n from "@/i18n";
import moment from "moment";
import {downloadHtml} from "@/mixins/htmlGenerator";
import dbKeyTelegramMonitor from "@/mixins/telegramMonitor";

export default {
  name: "CommentHistory",

  components: {CommentSearchPagination, CommentTree},

  mixins: [savePdf, filters, imageMixin, saveDoc, dbKeyTelegramMonitor],

  data() {
    return {
      meta: {},
      groups: [],
      localDataStatus: [],
      activeTab: null,


      isDownloadHtml: false,
      structureComments: [],
      authorName: '',
      isLoaded: true,
      matches_count: 0,
      offset: 0,
      request_id: 0,
      source_id: 0,
      currentPage: 0,
      enablePDF: true,
      results: [],
      isLoading: false,
      groupCurrent: [],
      parentKey: '',
    }
  },

  mounted() {
    this.getGroups(this.$route.params.id);
  },

  beforeDestroy() {
    this.setClearSearchRates();
  },

  computed: {
    ...mapGetters('search_comment', ['telegramGroupComments', 'metaComment']),
    ...mapGetters('search_rates', ['search_rates', 'servicesList']),
  },

  methods: {
    ...mapMutations('search_comment', ['setLocalTelegramComments', 'setClearTelegramGroupComments']),
    ...mapMutations('search_rates', ['setClearSearchRates']),

    async createHtml(full = false) {
      this.isDownloadHtml = true;

      downloadHtml(full, this.metaComment, this.token).then(isStatus => {
        this.isDownloadHtml = !isStatus;
      });
    },

    closeModal() {
      this.promptPdfActive = false;
    },

    async getGroups(id) {
      let dbResults = await Result.getResult(Number(id));

      this.meta = dbResults.result.meta;
      this.groups = dbResults.result.results.find(() => true);

      this.meta.payload.requestBody.author_id = await this.getUserIds(this.meta.parent);

      for (const group of this.groups.search_results[0].groups) {
        this.checkLocalData(group.id, this.meta.payload.requestBody);
      }

      await this.$store.dispatch('search_rates/getSearchRates');

      this.getComments(this.groups.search_results.find(Boolean).groups.find(Boolean).id);
    },

    async nextPage(currentPage, limit = 20) {
      if (currentPage === this.currentPage) return;

      this.isLoaded = false;
      this.currentPage = currentPage;

      this.telegramGroupComments.offset = this.calculateOffset(currentPage);

      if (typeof this.telegramGroupComments.offset === 'boolean') {
        await this.getComments(this.meta.payload.requestBody.group_id[0]);
        return;
      }

      let data = {
        body: {limit: limit, offset: this.telegramGroupComments.offset},
        query: {request_id: this.request_id, source_id: this.source_id}
      };

      let childKey = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);

      let dbResults = await Result.getResultByKey(childKey);

      if (typeof dbResults.result.results[0].search_results[0].commentPaginations === 'undefined') {
        dbResults.result.results[0].search_results[0].commentPaginations = [];
      }

      if (typeof dbResults.result.results[0].search_results[0].commentPaginations[currentPage] === 'undefined') {
        await this.$store.dispatch('search_comment/getTelegramGroupCommentsPaginate', data);
        this.buildTreeComments();
        dbResults.result.results[0].search_results[0].commentPaginations[currentPage] = this.structureComments;
        await Result.updateResult(dbResults.key, dbResults.result)
      }

      this.structureComments = dbResults.result.results[0].search_results[0].commentPaginations[currentPage];

      this.results = dbResults.result.results;
      this.isLoaded = true;
    },

    calculateOffset(currentPage) {
      let offset = false;

      if (currentPage > 1) {
        offset = (currentPage * 10) - 10;
      }

      return offset;
    },

    async checkLocalData(groupId) {
      this.meta.payload.requestBody.group_id = [groupId];

      let key = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);
      let res = await Result.getResultByKey(key);
      this.localDataStatus.push({groupId: groupId, status: typeof res !== 'undefined'});
    },

    selectTab(id) {
      this.setClearTelegramGroupComments();
      this.currentPage = 0;
      this.getComments(id);
    },

    async getComments(id) {
      this.isLoaded = false;
      this.activeTab = id;
      this.meta.payload.requestBody.group_id = [id];
      let childKey = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);

      await this.$store.dispatch('search_comment/getLocalTelegramGroupComments', childKey);

      if (Object.keys(this.telegramGroupComments).length) {
        this.structureComments = this.telegramGroupComments.search_results[0].comments;

        this.matches_count = this.telegramGroupComments.matches_count;
        this.offset = this.telegramGroupComments.offset;
        this.request_id = this.telegramGroupComments.request_id;
        this.source_id = this.telegramGroupComments.source_id;

        this.results = this.telegramGroupComments;
      }

      if (!Object.keys(this.telegramGroupComments).length) {
        await this.getRemoteData(id);
      }

      this.isLoaded = true;
    },

    async getRemoteData(groupId) {
      let dataResponse = await this.$store.dispatch('search_comment/getTelegramGroupComments', this.meta.payload.requestBody);
      this.buildTreeComments()

      dataResponse[0].search_results = [this.getGroupById(groupId)]
      dataResponse[0].search_results[0]['comments'] = this.structureComments;
      let childKey = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);

      let payload = {
        cache_search_enabled: true,
        online_search_enabled: false,
        value: this.meta.payload.requestBody.author.value,
        sources: ['Telegram_scr_2023'],
        childKey: childKey,
        countResult: dataResponse[0].total_count,
        requestBody: this.meta.payload.requestBody,
      }

      dataResponse[0]['param'] = this.meta.payload.requestBody.author.param;

      await this.$store.dispatch('contacts/cacheWithResultsChild', {
        data: dataResponse,
        type: 'telegram_monitor',
        parentKey: this.meta.parent,
        payload
      });

      const item = this.localDataStatus.find((el) => el.groupId === groupId);
      item.status = true;
    },


    async getUserIds(key) {
      let dbUsers = await Result.getResultByKey(key);

      return await dbUsers.result.results.filter(item => {
        return item.source === "Telegram_scr_2023";
      }).flatMap(item => {
        return item.search_results.map(subItem => subItem.id);
      });
    },

    buildTreeComments() {
      const map = {};

      this.telegramGroupComments.search_results.forEach(item => {
        item.children = [];
        map[item.id] = item;
      });

      const result = [];

      const calculateLevel = (item, currentLevel = 0) => {
        item.level = currentLevel;
        item.author = this.getAuthorName(item.author_id);
        item.photos = item.author.photos;
        item.data = item.author.data;

        item.children.forEach(child => {
          calculateLevel(child, currentLevel + 1);
        });
      };

      this.telegramGroupComments.search_results.forEach(item => {
        if (item.parent_id && map[item.parent_id]) {
          map[item.parent_id].children.push(item);
        } else if (item.reply_to_id && map[item.reply_to_id]) {
          map[item.reply_to_id].children.push(item);
        } else {
          result.push(item);
        }
      });

      result.forEach(rootComment => {
        calculateLevel(rootComment);
      });

      this.structureComments = result;
    },

    getAuthorName(authorId) {
      return this.telegramGroupComments.relative_results.authors.find(author => author.id === authorId)
    },

    randomCommentCurrentAuthor(comments) {
      if(!Object.keys(this.telegramGroupComments).length) return '';

      let author = this.telegramGroupComments.relative_results.authors.filter(author => this.meta.payload.requestBody.author_id.some(id => id === author.id));

      let authorComment = {}
      if (comments.author_id === author.find(Boolean).id) {
        authorComment = comments;
      }

      if (!Object.keys(authorComment).length) {
        authorComment = comments.children.filter(comment => author.some(xxx => xxx.id === comment.author_id));

        if (Object.keys(authorComment).length) {
          authorComment = authorComment.shift();
        }
      }

      return authorComment ? `${authorComment?.text} <span class="m-l-10 fst-italic text-muted">${moment.unix(authorComment.date).format('DD.MM.YYYY')} ${moment.unix(authorComment.date).format('HH:mm')}</span>` : "";
    },

    selectedLocale() {
      if (i18n.locale === 'ua') {
        return 'uk'
      } else {
        return i18n.locale
      }
    },

    toCamelCase(code) {
      return code.replace(/([a-zA-Z0-9]+)_([a-zA-Z0-9])/g, (_, prefix, nextChar) => {
        return prefix + nextChar.toUpperCase();
      });
    },

    getGroupById(groupId) {
      return this.telegramGroupComments.relative_results.groups.find(group => group.id === groupId)
    },
  }
}
</script>

<style scoped>
.group-name {
  position: absolute;
  top: 10px;left: 220px;
}

@media screen and (max-width: 991px) {
  .group-name {
    position: absolute;
    top: -52px;
    left: 150px;
  }
}

.main-comment >>> .comment-tab {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 4px solid #ffffff;
  border-radius: 0;
  color: #37474f;
}

.main-comment >>> .comment-tab.active {
  border-left: 4px solid #4099ff;
  color: #4099ff;
}
</style>