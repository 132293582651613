<template>
  <div class="pagination-container">
    <b-pagination
        v-if="totalRecords > 10"
        :disabled="!loadedStatus"
        :total-rows="totalRecords"
        use-router
        v-model="currentPage"
        first-number
        last-number
        :per-page="perPage"
        :limit="viewCountBtn"
        order="is-centered"
        @input="onPageChange"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "CommentSearchPagination",

  props: {
    totalRecords: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    loadedStatus: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      viewCountBtn: 15,
    };
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    handleResize() {
      if (window.innerWidth < 1199 && window.innerWidth > 992) {
        this.viewCountBtn = 8;
      } else if(window.innerWidth <= 992) {
        this.viewCountBtn = 5;
      } else {
        this.viewCountBtn = 15;
      }
    },

    onPageChange() {
      this.$emit("page-changed", this.currentPage);
    },
  },
}
</script>

<style scoped>

</style>