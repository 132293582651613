import axios from "@/configs/axios";
import {Result} from '@/models/result'

const state = {
    telegramComment: {},
    telegramGroupComments: {},
    historyParams: {},
    meta: {},
    metaComment: {},
}
const getters = {
    telegramComment: state => state.telegramComment,
    telegramGroupComments: state => state.telegramGroupComments,
    historyParams: state => state.historyParams,
    meta: state => state.meta,
    metaComment: state => state.metaComment
}

const actions = {
    async getTelegramComments({commit, dispatch}, data) {
        let result = await axios({url: 'content_search/preview', data: data,  method: 'POST'});

        if (result.status === 200) {
            commit('setTelegramComments', result.data.results)
            return result.data.results;
        }

        return [];
    },

    getTelegramGroupComments({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            axios({url: 'content_search', data: data,  method: 'POST'})
                .then((resp) => {
                    commit('setTelegramGroupComments', resp.data.results)
                    resolve(resp.data.results);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getLocalPreviewTelegramGroupComments({commit, dispatch}, childKey) {
        let dbResponse = await Result.getResultByKey(childKey);

        if (typeof dbResponse !== 'undefined') {
            commit('setTelegramComments', dbResponse.result.results);
            commit('setHistoryParams', dbResponse.result.meta.payload.requestBody);
            return dbResponse.result;
        }

        commit('setClearTelegramComments');
        return {};
    },

    async getLocalTelegramGroupComments({commit, dispatch}, key) {
        let dbResponse = await Result.getResultByKey(key);

        if (typeof dbResponse !== 'undefined') {
            commit('setTelegramGroupComments', dbResponse.result.results);
            commit('setMeta', dbResponse.result.meta);
            commit('setMetaComment', dbResponse.result.meta);
            return dbResponse.result;
        }

        commit('setClearTelegramGroupComments');
        return {};
    },

    getTelegramGroupCommentsPaginate({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `content_search/request/${data.query.request_id}/source/${data.query.source_id}`, data: data.body,  method: 'POST'})
                .then((resp) => {
                    commit('setLocalTelegramComments', resp.data)
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async removeChildMainSearch({commit, dispatch}, key) {
        await Result.deleteResult(key);
    },
}

const mutations = {
    setHistoryParams(state, historyParams) {
        state.historyParams = historyParams;
    },

    setMeta(state, meta) {
        state.meta = meta;
    },

    setMetaComment(state, meta) {
        state.metaComment = meta;
    },

    setTelegramComments(state, payload = []) {
        state.telegramComment = payload.filter(item => item.source === 'Telegram_scr_2023').shift();
    },

    setClearTelegramComments(state) {
        state.telegramComment = {};
    },

    setClearTelegramGroupComments(state) {
        state.telegramGroupComments = {};
    },

    setTelegramGroupComments(state, payload = []) {
        state.telegramGroupComments = payload.filter(item => item.source === 'Telegram_scr_2023').shift();
    },

    setLocalTelegramComments(state, payload) {
        state.telegramGroupComments = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}